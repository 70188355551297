import { i18n } from "../lang/lang.js";
import { commonUtil } from "../assets/scripts/js/CommonUtil";
import { dateTimeHelper } from "../assets/scripts/js/DateTimeHelper";

export default {
  data: () => ({
    // バリデーションルール
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      inputNumber52: (value) => {
        let ar = String(value).replaceAll(",", "");
        if (value != undefined) {
          if (ar.includes(".")) {
            let numL = ar.split(".");

            if (numL[0].length > 5 || numL[1].length > 3)
              return i18n.tc("check.chk_inputNumber_5-3");
          } else {
            if (ar.length > 5) {
              return i18n.tc("check.chk_inputNumber_5-3");
            }
          }
        }
        return true;
      },
      inputNumber53: (value) => {
        let ar = String(value).replaceAll(",", "");
        if (value != undefined) {
          if (ar.includes(".")) {
            let numL = ar.split(".");

            if (numL[0].length > 5 || numL[1].length > 3)
              return i18n.tc("check.chk_inputNumber_5-3");
          } else {
            if (ar.length > 5) {
              return i18n.tc("check.chk_inputNumber_5-3");
            }
          }
        }
        return true;
      },
      inputNum52: (value) => {
        let ar = String(value).replaceAll(",", "");
        if (value != undefined) {
          if (ar.includes(".")) {
            let numL = ar.split(".");

            if (numL[0].length > 5 || numL[1].length > 2)
              return i18n.tc("check.chk_inputNumber_5-2");
          } else {
            if (ar.length > 5) {
              return i18n.tc("check.chk_inputNumber_5-2");
            }
          }
        }
        return true;
      },
      inputNumber93: (value) => {
        let ar = String(value).replaceAll(",", "");

        if (value != undefined) {
          if (ar.includes(".")) {
            let numL = ar.split(".");

            if (numL[0].length > 9 || numL[1].length > 3)
              return i18n.tc("check.chk_inputNumber_9-3");
          } else {
            if (ar.length > 9) {
              return i18n.tc("check.chk_inputNumber_9-3");
            }
          }
        }
        return true;
      },
      inputDateCheck: (value1, value2) =>
        new Date(value1).getTime() < new Date(value2).getTime() ||
        i18n.tc("check.chk_inputFromValidate"),
      inputUnitCheck: (value1, value2) => !value2 || !!value1 || i18n.tc("check.chk_input"),
      inputinventCheck: (value1, value2) => !value2 || !!value1 || i18n.tc("check.chk_input"),
      input: (val) => {
        var check = /^([0-9]{1,3}|0)([.][0-9]{1,2})?$/g.test(val);
        if (val != null) {
          if (check == true) {
            return true;
          } else {
            return i18n.tc("check.chk_inputNumber_3-2");
          }
        } else {
          return true;
        }
      },
      inputNumberOverZero: (value) => {
        let numberVal = value;
        if (numberVal == 0) {
          return i18n.tc("check.chk_inputMoreOneDot");
        }
        return true;
      },
      // 整数3桁少数2桁
      inputNumber: (val) => {
        // 数字以外の場合
        if (isNaN(Number(commonUtil.zen2han(val).replaceAll(",", "")))) {
          return i18n.tc("check.chk_inputNumber");
        }
        // 整数3桁少数2桁
        var check = /^[0-9][,]?([0-9]{0,4}|0)([.][0-9]{0,2})?$/g.test(val);
        if (check == true) {
          return true;
        } else {
          return i18n.tc("check.chk_inputNumber_3-2");
        }
      },
      // 整数4桁少数2桁
      inputNumber_m: (val) => {
        var check = /^[0-9]{0,1}[,]?([0-9]{0,4}|0)([.][0-9]{0,2})?$/g.test(val);
        if (check == true) {
          return true;
        } else {
          return i18n.tc("check.chk_inputNumber_4-2");
        }
      },
      // 整数6桁少数2桁
      inputNumbers: (val) => {
        var check = /^[0-9]{0,3}[,]?([0-9]{0,4}|0)([.][0-9]{0,2})?$/g.test(val);
        if (check == true) {
          return true;
        } else {
          return i18n.tc("check.chk_inputNumber_6-2");
        }
      },
      // 整数8桁少数2桁
      inputNumber_m2: (val) => {
        var check =
          /^[0-9]{0,2}[,]?([0-9]{0,3}|0)[0-9]{0,3}[,]?([0-9]{0,4}|0)([.][0-9]{0,2})?$/g.test(val);
        if (check == true) {
          return true;
        } else {
          return i18n.tc("check.chk_inputNumber_8-2");
        }
      },
      inputRequiredCreate: (value1, value2) => !value2 || !!value1 || i18n.tc("check.chk_input"),
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      warehouseRequired: (value) => !!value || i18n.tc("check.chk_inputWarehouse"),
      isNumber: (value) =>
        value == null ||
        value == "" ||
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      yyyymmdd_create: (value) =>
        !value ||
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isZenkakuKana: (value) =>
        value == null || !!value.match(/^[ァ-ヶー]*$/) || i18n.tc("check.chk_inputZenkakuKana"),
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      isRangeCheck: (value) => {
        if (value != null && value != undefined && value != "") {
          if (0 >= Number(commonUtil.zen2han(value).replaceAll(",", "")))
            return i18n.tc("check.chk_inputMoreOne");
        }
        return true;
      },
      isRangeCheck0: (value) => {
        if (value != null && value != undefined && value != "") {
          if (1 > Number(commonUtil.zen2han(value).replaceAll(",", "")))
            return i18n.tc("check.chk_inputMore");
        }
        return true;
      },
      isNumberPop: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      isNumbers: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
      isRangeCheckBara: (value) =>
        0 <= Number(commonUtil.zen2han(value).replaceAll(",", "")) ||
        i18n.tc("check.chk_inputMoreOne"),
      isTime: (value) =>
        value == null ||
        value == "" ||
        value.match("^([0-1][0-9]|2[0-3])[0-5][0-9]$") ||
        i18n.tc("check.chk_inputTime"),
      limitNumLength: (value) =>
        value == null ||
        String(value).replaceAll(",", "").length < 10 ||
        i18n.tc("check.chk_limitNumLength"), // 文字数の制約
      limitLength: (value) => value.length <= 50 || i18n.tc("check.chk_inputMorefifty"),
      limitLength50: (value) =>
        value == null || value.length <= 50 || i18n.tc("check.chk_inputMorefifty"), // 文字数の制約
      limitNumLength11: (value) =>
        String(value).replaceAll(",", "").length < 12 || i18n.tc("check.chk_limitLength_11"),
      limiNumLenght7: (value) => String(value).length == 7 || i18n.tc("check.chk_inputPostCd"),
      maxTotalCapa: (value) => value > 9999999.999999 || i18n.tc("check.chk_maxTotalCapa"),
      serialCheck: (value, list, index, value1) => {
        if (value != null && value != undefined && value != "") {
          for (var i = 0; i < list.length; i++) {
            if (index != i) {
              if (list[i].productCd == value1 && list[i].serial == value) {
                return i18n.tc("check.chl_input_serial");
              }
            }
          }
        }
        return true;
      },
      fileRequired: (value) => (!!value && !!value.name) || i18n.tc("check.chk_select"),
      /**
       * 文字桁チェック
       *  @param {String} value - 値
       *  @param {Integer} length - 桁
       */
      isStrLen: (value, length) => {
        // 値の存在チェック
        if (value) {
          // 型チェック
          if (typeof value === "string" && Number.isInteger(length)) {
            // 桁チェック
            if (length < value.length) {
              return i18n.tc("check.chk_isStrLen", 0, [length]);
            }
          } else {
            // パラメータ指定ミス
            return i18n.tc("check.chk_impossible");
          }
        }
        return true;
      },
      /**
       * 自然数チェック
       * @param {String} value - 値
       * @param {Integer} minVal - 最小
       * @param {Integer} maxVal - 最大
       */
      isNaturalNum: (value, minVal, maxVal) => {
        const pattern = /\d+(?:\.\d+)?/g;
        // 値の存在チェック
        if (value) {
          // （数値）型チェック
          if (value.match(pattern)) {
            // （自然数）型チェック
            if (Number.isInteger(Number(value)) && value.indexOf(".") === -1) {
              var min = 1;
              var max = 999;
              // 範囲の型チェック
              if (minVal && maxVal) {
                if (
                  Number.isInteger(minVal) &&
                  Number.isInteger(maxVal) &&
                  0 < minVal &&
                  minVal < maxVal
                ) {
                  min = minVal;
                  max = maxVal;
                } else {
                  // パラメータ指定ミス
                  return i18n.tc("check.chk_impossible");
                }
              }
              // 範囲チェック
              if (value < min || max < value) {
                return i18n.tc("check.chk_inputNumMinMax", 0, [min, max]);
              }
            } else {
              return i18n.tc("check.chk_isNaturalNum");
            }
          } else {
            return i18n.tc("check.chk_isHalfNums");
          }
        }
        return true;
      },
      /**
       * 未来日チェック
       * @param {String} value - 値
       * @param {String} referenceDate - 基準日
       * @param {String} param - パラメータ
       */
      isFutureDate: (value, referenceDate, param) => {
        if (
          !!value &&
          !!referenceDate &&
          !!param &&
          new Date(value).getTime() < new Date(referenceDate).getTime()
        ) {
          return i18n.tc("check.chk_inputFutureDate2", 0, [param]);
        }
        return true;
      },
      /**
       * 相関必須チェック
       * @param {String} value - 値
       * @param {String} correlationValue - 片方の値
       * @param {String} param - パラメータ
       */
      isCorrelationRequired: (value, correlationValue, param) => {
        if (!value && correlationValue && param) {
          return i18n.tc("check.chk_inputVariable", 0, [param]);
        }
        return true;
      },
      //最小桁数チェック
      inputMinLength: (value, length) => {
        if (value != null && value != undefined && value != "") {
          if (value.length < length) return length.toString() + i18n.tc("check.chk_inputMinLength");
        }
        return true;
      },
      //電話番号,FAX番号チェック(ハイフンあり)
      isNumberHyphen: (value) =>
        !value ||
        (!!value &&
          !isNaN(Number(commonUtil.zen2han(value.replaceAll("-", "")).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
      // 値１が入力された場合は値２必須チェック
      isValue2Required: (value1, value2) => !value1 || !!value2 || i18n.tc("check.chk_input"),
      // 値１と値２どちらか一方のみ入力可能チェック
      onlyOneInputRequired: (value1, value2, lbl1, lbl2) =>
        !value1 || !value2 || i18n.tc("check.chk_inputOnlyOneValue", 0, [lbl1, lbl2]),
      // 4つの値のうち、１つだけ入力可能チェック
      onlyOneInputRequired2: (value1, value2, value3, value4, target) => {
        let input = [value1, value2, value3, value4];
        let check = input.filter((str) => !str);
        if (target === "inquiryNumber" && !value1) {
          return true;
        }

        if (target === "freightOrderNumber" && !value2) {
          return true;
        }

        if (target === "lotNumber" && !value3) {
          return true;
        }

        if (target === "invNum" && !value4) {
          return true;
        }

        if (check.length <= 2) {
          return i18n.tc("check.chk_inputOnlyOneValue2");
        }

        return true;
      },
    },
  }),
};
